import React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];

  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location = '';

  if (typeof document != 'undefined') {
    location = document.location;
  }

  const active = 
    location && (location.pathname === url + '/' || location.pathname === url || location.pathname === config.gatsby.pathPrefix + url);

  let actived = '';

  if(location.pathname){
    actived = location.pathname.includes(url);
  }

  let urlMain = config.sidebar.forcedNavOrder;

  let urlSave = '';
  if (url){
    urlSave = url.replace('/', '');
  }

  const calculatedClassName = `${className} item ${ actived ? 'active' : ''}`;

  return (

    
      <li className={calculatedClassName}>
        {title && (
          <Link to={url} onClick={collapse}>
            {title}
            {!config.sidebar.frontLine && title && hasChildren ? (
              <button onClick={collapse} aria-label="collapse" className="collapser"> 
                { actived ? <OpenedSvg /> : <ClosedSvg />}
              </button>
            ) : null}
          </Link>
        )}

        { hasChildren && !urlSave && (!isCollapsed || actived) ? (
          <ul>
            {items.map((item, index) => (
              <TreeNode
                key={item.url + index.toString()}
                setCollapsed={setCollapsed}
                collapsed={collapsed}
                {...item}
              />
            ))}
          </ul>
        ) : null}

      </li>
 
  );
};

export default TreeNode;
