import React from 'react';
import Link from './link';
import config from '../../config.js';

import { PageBreadCrumbs } from './styles/PageBreadCrumbs';

const BreadCrumbs = ({ mdx, nav }) => {
  let currentIndex;

  const currentPaginationInfo = nav.map((el, index) => {
    if (el && el.url === mdx.fields.slug) {
      currentIndex = index;
    }
  });

  let location = '';

  if (typeof document != 'undefined') {
    location = document.location;
  }

  let urlMain = config.sidebar.forcedNavOrder;

  let urlSave = '';

let i = 0;
if(location){
	 while(i < urlMain.length){
	  if(location.pathname.includes(urlMain[i])){
	    urlSave = urlMain[i];
	  }
	  if(urlSave){
	    break;
	  }
	  i++;
	}
}


  return (
    <PageBreadCrumbs>
      {location.pathname !== '/' ? (
        <a href='/'>Главная</a>
      ) : null}      
      
      {urlSave ? (
        <span>/</span>
      ) : null}

      {urlSave ? (
           <a data={urlSave} href={'/' + urlSave}>{urlSave}</a>
      ) : null}

      {currentIndex >= 0 && nav[currentIndex].url !== '/' + urlSave ? (
          <span>/</span>
      ) : null}

      {currentIndex >= 0 && nav[currentIndex].url !== '/' + urlSave ? (
          <a data={urlSave} href={nav[currentIndex].url}>{nav[currentIndex].title}</a>
      ) : null}
      
    </PageBreadCrumbs>
  );
};

export default BreadCrumbs;
