import React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';

const TreeNode = ({ className = '', setCollapsed, collapsed, url, title, items, ...rest }) => {
  const isCollapsed = collapsed[url];

  const collapse = () => {
    setCollapsed(url);
  };

  let hasChildren = items.length !== 0;

  let location = '';

  if (typeof document != 'undefined') {
    location = document.location;
  }

  const active = 
    location && (location.pathname === url + '/' || location.pathname === url || location.pathname === config.gatsby.pathPrefix + url);

  let actived = '';

  if(location.pathname){
    actived = location.pathname.includes(url);
  }

  
  const calculatedClassName = `${className} item ${ active ? 'active' : ''}`;

  let urlMain = config.sidebar.forcedNavOrder;

  let urlSave = '';
  if (url){
    urlSave = url.replace('/', '');
  }

  let idactive = '';
  if(active){
    idactive = 'activ';
  }

  return (
    <li id={idactive} className={calculatedClassName} data={config.sidebar.frontLine}>
      {title && urlMain.includes(urlSave) === false ? (
        <Link to={url} onClick={collapse}>
          {title}
          {!config.sidebar.frontLine && title && hasChildren ? (
            <button onClick={collapse} aria-label="collapse" className="collapser" data={urlSave}> 
              {actived ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : null}
        </Link>
      ) : null}

      {(!urlSave || urlMain.includes(urlSave) || active || actived) && (!isCollapsed || actived) && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              {...item}
            />
          ))}
        </ul>
      ) : null}


    </li>
  );
};

export default TreeNode;
