import styled from '@emotion/styled';

export const PageBreadCrumbs = styled('div')`
  margin: 0px;
  padding: 0px;
  width: auto;
  display: flex;
  grid-template-rows: auto;
  column-gap: 24px;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);

  

  @media (max-width: 767px) {
  
  }
`;
